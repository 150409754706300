<template>

  <element>
    <div class="builder-wrapper">
      <!-- builder sidebar starts -->
      <BuilderSidebar/>
      <!-- builder sidebar ends -->
      <div class="main-container" :class="isSidebarOpen ? 'sidebar-open' : 'sidebar-close'">
        <!-- builder topbar starts -->
        <BuilderTopbar/>
        <!-- builder topbar ends -->
        <br>

        <b-row>
          <b-col cols="4">
            <b-form-group label="name" label-for="name" style="padding:10px">
              <template #label>{{ $t('labels.name') }}</template>
              <b-form-input id="name" type="text" v-model="name"/>

            </b-form-group>
          </b-col>
        </b-row>

        <!-- builder Main starts -->
        <BuilderMain/>
        <!-- builder Main ends -->
        <br>
        <div class="text-center">
          <div class="row justify-content-center px-2">
            <b-overlay :show="loadingSave" rounded="sm" v-if="!isEdit">
              <b-button variant="primary" class="mr-2" @click="getObject()">
                {{ $t('buttons.save') }}
              </b-button>
            </b-overlay>
            <b-overlay :show="loadingSave" rounded="sm" v-if="isEdit">
              <b-button variant="primary" class="mr-2" @click="updateFooter()">
                {{ $t('buttons.update') }}
              </b-button>
            </b-overlay>

            <b-button type="button" variant="outline-secondary"
                      @click="onCancel()">
              {{ $t('buttons.cancel') }}
            </b-button>
          </div>
          <!-- <b-button
        variant="primary"
        class="mr-2"
        @click="getObject()"
      >
        Previsualización
      </b-button> -->

        </div>
        <br>
        <PreviewMain :previewFooter="previewFooter"/>
      </div>
    </div>
    <br>
  </element>

</template>

<script>
import {
  BTab, BTabs, BCard, BCol, BRow, BFormInput, BFormGroup, BOverlay
} from 'bootstrap-vue'
import {ref, onUnmounted, computed} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import footertoreModule from '../footerStoreModule'
import BuilderTopbar from "@/builder_components/builder/BuilderTopbar.vue";
import BuilderSidebar from "@/builder_components/builder/BuilderSidebar.vue";
import BuilderMain from "@/builder_components/builder/BuilderMain.vue";
import PreviewMain from "@/builder_components/preview/PreviewMain.vue";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import {useToast} from "vue-toastification/composition";
import i18n from "@/libs/i18n";
import {useRouter} from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {showErrorToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BuilderSidebar,
    BuilderTopbar,
    BuilderMain,
    BOverlay,
    PreviewMain
  },
  setup() {
    const FOOTER_APP_STORE_MODULE_NAME = 'moduleFooter'
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

    const footer = ref(undefined)
    const name = ref('')
    const loadingSave = ref(false)
    const dbSections = ref(undefined)
    const toast = useToast()
    const {route} = useRouter();
    const isEdit = ref(false)
    const id = ref(undefined)

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(FOOTER_APP_STORE_MODULE_NAME)) store.registerModule(FOOTER_APP_STORE_MODULE_NAME, footertoreModule)

    onUnmounted(() => {
      if (store.hasModule(FOOTER_APP_STORE_MODULE_NAME)) store.unregisterModule(FOOTER_APP_STORE_MODULE_NAME)
    })

    const editTemplate = computed(() => {
      return store.state["app-whitelabel-templates"].editTemplate;
    });

    const previewFooter = computed(() => {
      return store.getters.getFooter
    })

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          footer: value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: "templateData.footer",
            }
        );
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const loadDataModeEdit = async () => {

      await store.dispatch('moduleFooter/fetchFooter', {id: router.currentRoute.params.id})
          .then(response => {
            const dbSections = response.data.sections
            name.value = response.data.name
            id.value = response.data._id
            store.commit('setSections', dbSections)
            isEdit.value = true
          }).catch(error => {
                console.log(error)
              }
          )
    }

    const getObject = async () => {
      try {
        loadingSave.value = true
        const payload = {
          name: name.value,
          description: '',
          sections: store.getters.getFooter,
          whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
          templateId: editTemplate.value._id
        }
        // this.$store.dispatch("getObject", {name:this.name});
        const r = await store.dispatch("moduleFooter/addFooter", payload);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('new_footer_added'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        let query = {}
        const {templateId} = route.value.query

        if (templateId) {
          query = {id: templateId}
        }

        updatePreview(r.data)
        await updateTemplate()

        await router.push({name: 'app-template-preview', query})

      } catch (e) {
        console.log(e)
      } finally {
        loadingSave.value = false
      }
    }


    const updateFooter = async () => {

      try {
        loadingSave.value = true
        const payload = {
          id: id.value,
          footerData: {
            name: name.value,
            description: '',
            sections: store.getters.getFooter
          }
        }
        // this.$store.dispatch("getObject", {name:this.name});
        const r = await store.dispatch("moduleFooter/updateFooter", payload);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('footer_updated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        
        updatePreview(r.data)
        await updateTemplate()

        let query = {}
        const {templateId} = route.value.query

        if (templateId) {
          query = {id: templateId}
        }

        await router.push({name: 'app-template-preview', query})

      } catch (e) {
        console.log(e)
      } finally {
        loadingSave.value = false
      }
    }

    const isSidebarOpen = computed(() => store.getters.isSidebarOpen);

    if (router.currentRoute.params.id != null) {
      loadDataModeEdit()
    }

    const onCancel = async () => {

      let query = {}
      const {templateId} = route.value.query

      if (templateId) {
        query = {id: templateId}
      }

      await router.push({name: 'app-template-preview', query})
    }

    return {
      editTemplate,
      footer,
      name,
      loadingSave,
      dbSections,
      isEdit,
      isSidebarOpen,
      previewFooter,
      loadDataModeEdit,
      getObject,
      onCancel,
      updateFooter
    }
  },

}
</script>


<style lang="scss" scoped>
.builder-wrapper {
  height: 100%;
  display: flex;
  justify-content: left;
  margin-top: 25px;

  .main-container {
    background: #ffffff;
    width: 100%;
    margin: 0;
    transition: margin-left 0.5s;

    &.sidebar-open {
      margin-left: 393px;
    }
  }
}
</style>
